var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_modules_task_unit_hover" },
    [
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "title_group bbox" }, [
          _c("div", { staticClass: "title" }, [
            _vm.free
              ? _c("span", { staticClass: "free pr-4" }, [_vm._v("免费")])
              : _vm._e(),
            _c("span", { staticClass: "pl-4" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("div", { staticClass: "descript pt-6" }, [
            _vm._v(_vm._s(_vm.content)),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "icon_group d-flex align-center justify-space-between py-10 bbox px-20",
          },
          [
            _c("div", { staticClass: "time_group d-flex align-center" }, [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/images/shopping_mail/time_icon.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  _vm._s(_vm.$moment(_vm.create_time).format("YYYY年MM月DD日"))
                ),
              ]),
            ]),
            _c("div", { staticClass: "data_group d-flex align-center" }, [
              _vm.sale
                ? _c(
                    "div",
                    { staticClass: "item_group d-flex align-center mr-10" },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/shopping_mail/sale_icon.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.sale)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.readamount
                ? _c("div", { staticClass: "item_group d-flex align-center" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require("@/assets/images/shopping_mail/view_icon.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.readamount)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        0
          ? _c("div", { staticClass: "category_group py-10 bbox px-20" }, [
              _vm._v("会展专业 / 会展营销 / 市场营销 "),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass:
            "payment_group d-flex align-center justify-space-between px-20 py-16",
        },
        [
          _vm.show_price
            ? _c("div", { staticClass: "price_group" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v("¥" + _vm._s(_vm.price)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "opeation_group" }, [
            0
              ? _c("div", { staticClass: "button" }, [_vm._v("加入定制清单")])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }