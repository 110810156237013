var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_modules_task_unit_major cp" },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("div", { staticClass: "placeholder bbox d-flex flex-column" }, [
            _c("div", { staticClass: "title bbox" }, [
              _vm.free
                ? _c("span", { staticClass: "free pr-4" }, [_vm._v("免费")])
                : _vm._e(),
              _c("span", { staticClass: "pl-4" }, [_vm._v(_vm._s(_vm.title))]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "info_group d-flex align-center justify-space-between flex-shrink-0 bbox",
              },
              [
                _vm.create_user_thumbnail
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        src: `${_vm.$avatar_url}${_vm.create_user_thumbnail}@!small200`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "data_group d-flex align-center" }, [
                  _vm.sale
                    ? _c(
                        "div",
                        { staticClass: "item_group d-flex align-center mr-10" },
                        [
                          _c("img", {
                            staticClass: "icon mr-4",
                            attrs: {
                              src: require("@/assets/images/shopping_mail/sale_icon.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.sale)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.readamount
                    ? _c(
                        "div",
                        { staticClass: "item_group d-flex align-center" },
                        [
                          _c("img", {
                            staticClass: "icon mr-4",
                            attrs: {
                              src: require("@/assets/images/shopping_mail/view_icon.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.readamount)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _c("hover-module", {
            staticClass: "hover_module",
            attrs: {
              pselected: _vm.pselected,
              ptask: _vm.ptask,
              show_price: _vm.show_price,
            },
          }),
          0
            ? _c("div", { staticClass: "hover_group" }, [
                _c("div", { staticClass: "info_group" }, [
                  _c("div", { staticClass: "title_group bbox" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", { staticClass: "free pr-4" }, [
                        _vm._v("免费"),
                      ]),
                      _c("span", { staticClass: "pl-4" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ]),
                    _c("div", { staticClass: "descript pt-6" }, [
                      _vm._v(_vm._s(_vm.content)),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "icon_group d-flex align-center justify-space-between py-10 bbox px-20",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "time_group d-flex align-center" },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/shopping_mail/time_icon.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$moment(_vm.create_time)
                                  .format("YYYY年MM月DD日")
                              )
                            ),
                          ]),
                        ]
                      ),
                      0
                        ? _c(
                            "div",
                            { staticClass: "data_group d-flex align-center" },
                            [_vm._m(0), _vm._m(1)]
                          )
                        : _vm._e(),
                    ]
                  ),
                  0
                    ? _c(
                        "div",
                        { staticClass: "category_group py-10 bbox px-20" },
                        [_vm._v("会展专业 / 会展营销 / 市场营销 ")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "payment_group d-flex align-center justify-space-between px-20 py-16",
                  },
                  [
                    _vm._m(2),
                    _c("div", { staticClass: "opeation_group" }, [
                      0
                        ? _c("div", { staticClass: "button" }, [
                            _vm._v("加入定制清单"),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item_group d-flex align-center mr-10" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/shopping_mail/sale_icon.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "text" }, [_vm._v("3245")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item_group d-flex align-center" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/shopping_mail/view_icon.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "text" }, [_vm._v("9.2w")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price_group" }, [
      _c("div", { staticClass: "price" }, [_vm._v("¥238000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }