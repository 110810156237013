<template>
  <div
    class="views_shopping_mail_modules_task_unit_major cp"
  >
    <div class="wrapper">
      <div class="placeholder bbox d-flex flex-column">
        <div class="title bbox ">
          <span class="free pr-4"
            v-if="free"
          >免费</span>
          <span class="pl-4">{{title}}</span>
        </div>
        <div class="info_group d-flex align-center justify-space-between flex-shrink-0 bbox">
          <img
            v-if="create_user_thumbnail"
            :src="`${$avatar_url}${create_user_thumbnail}@!small200`"
            alt=""
            class="avatar"
          >
          <div class="data_group d-flex align-center"
          >
            <div class="item_group d-flex align-center mr-10"
              v-if="sale"
            >
              <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
              <div class="text">{{sale}}</div>
            </div>
            <div class="item_group d-flex align-center"
              v-if="readamount"
            >
              <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
              <div class="text">{{readamount}}</div>
            </div>
          </div>
        </div>
      </div>
      <hover-module
        class="hover_module"
        :pselected="pselected"
        :ptask="ptask"
        :show_price="show_price"
      ></hover-module>
      <div class="hover_group"
        v-if="0"
      >
        <div class="info_group">
          <div class="title_group bbox">
            <div class="title">
              <span class="free pr-4">免费</span>
              <span class="pl-4">{{title}}</span>
            </div>
            <div class="descript pt-6">{{content}}</div>
          </div>
          <div class="icon_group d-flex align-center justify-space-between py-10 bbox px-20">
            <div class="time_group d-flex align-center">
              <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon">
              <div class="text">{{$moment(create_time).format('YYYY年MM月DD日')}}</div>
            </div>
            <div class="data_group d-flex align-center"
              v-if="0"
            >
              <div class="item_group d-flex align-center mr-10">
                <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon">
                <div class="text">3245</div>
              </div>
              <div class="item_group d-flex align-center">
                <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon">
                <div class="text">9.2w</div>
              </div>
            </div>
          </div>
          <div class="category_group py-10 bbox px-20"
            v-if="0"
          >会展专业 / 会展营销 / 市场营销 </div>
        </div>
        <div class="payment_group d-flex align-center justify-space-between px-20 py-16">
          <div class="price_group">
            <div class="price">¥238000</div>
          </div>
          <div class="opeation_group">
            <div class="button"
              v-if="0"
            >加入定制清单</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HoverModule from './Hover.vue';
export default {
  components: {
    HoverModule,
  },
  props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ptask: {
      type: Object,
      default: undefined,
    },
    show_price: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    title(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.title;
      }
      if(ptask){
        return ptask.name;
      }
      return '';
    },
    content(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.content;
      }
      if(ptask){
        return '';
      }
      return '';
    },
    create_time(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ptask){
        return ptask.createTime;
      }
      return '';
    },
    free(){
      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.price === 0;
      }
      return false;
    },
    create_user_thumbnail(){

      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.createuserthumbnail;
      }
      return false;
    },
    sale(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.sale;
      }
      if(ptask){
        return ptask.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.readamount;
      }
      if(ptask){
        return ptask.readamount;
      }
      return undefined;
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_task_unit_major{
  .wrapper{
    @include transition;
    transition-delay: 600;
    // width: 200px;
    width: 100%;
    // height: 160px;
    float: left;
    position: relative;
  }
  .placeholder{
    @include transition;
    width: 200px;
    height: 160px;
    position: relative;
    z-index: 2;
    opacity: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #E8EEFF;
    .title{
      padding: 15px 20px;
      flex: 1;
      width: 100%;
      line-height: 24px;
      border-bottom: 2px solid #f8f8f8;
      background: {
        image: url(~@/assets/images/shopping_mail/teaching_task.png);
        repeat: no-repeat;
        position: center right;
      }
    }
    .info_group{
      width: 100%;
      padding: 0 20px;
      height: 40px;
      font-size: 12px;
      color: #666;
      .avatar{
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 2px
      }
    }
  }
  // .hover_group{
  //   @include transition;
  //   // transform: scaleY(0);
  //   transform-origin: center top;
  //   opacity: 0;
  //   position: absolute;
  //   top:0;
  //   left:0;
  //   right: 0;
  //   height: 100%;
  //   background-color: #fff;
  //   border-radius: 4px;
  //   z-index: 3;
  //   overflow: hidden;
  //   .title_group{
  //     padding: 15px 20px;
  //     flex: 1;
  //     width: 100%;
  //     line-height: 24px;
  //     border-bottom: 2px solid #f8f8f8;
  //     background: {
  //       image: url(~@/assets/images/shopping_mail/teaching_task.png);
  //       repeat: no-repeat;
  //       position: center right;
  //     }
  //   }
  //   .title{
  //     font-size: 16px;
  //     line-height: 24px;
  //   }
  //   .descript{
  //     font-size: 14px;
  //     color: #999999;
  //     line-height: 24px;
  //   }
  //   .icon_group{
  //     font-size: 12px;
  //     color: #666666;
  //     line-height: 16px;
  //     .icon{
  //       margin-right: 4px;
  //     }
  //   }
  //   .category_group{
  //     font-size: 12px;
  //     color: #AAAAAA;
  //     line-height: 16px;
  //   }
  //   .payment_group{
  //     background-color: #f0f0f0;
  //     .price{
  //       font-size: 16px;
  //       font-weight: bold;
  //       color: #F3580C;
  //       line-height: 21px;
  //     }
  //     .hint{
  //       font-size: 12px;
  //       color: #884706;
  //       line-height: 16px;
  //     }
  //     .button{
  //       width: 120px;
  //       height: 34px;
  //       border-radius: 4px;
  //       font-size: 14px;
  //       color: #FFFFFF;
  //       line-height: 34px;
  //       text-align: center;
  //       background-image: linear-gradient(159deg, #68E5B6 0%, #016BA2 100%);
  //     }
  //   }
  // }
  .title{
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .free{
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    padding: 0 4px;
    line-height: 18px;
    background: linear-gradient(134deg, #00A9D3 0%, #006BA7 100%);
    border-radius: 4px;
    text-align: center;
  }
  .hover_module{
    @include transition(0.2s);
    // transition-delay: 0.3s;
    opacity: 0;
    top:0;
    left: 0;
    transform-origin: top center;
    right: 0;
    position: absolute;
    z-index: 3;
    transform: scaleY(0);
  }
  &:hover{
    .hover_module{
      opacity: 1;
      transform: scaleY(1);
      top: -4px;
      left: -40px;
      right: -40px;
    }
  }
}
</style>
