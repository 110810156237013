<template>
  <div
    class="views_shopping_mail_modules_task_unit_hover"
  >
    <div class="info_group">
      <div class="title_group bbox">
        <div class="title">
          <span class="free pr-4"
            v-if="free"
          >免费</span>
          <span class="pl-4">{{title}}</span>
        </div>
        <div class="descript pt-6">{{content}}</div>
      </div>
      <div class="icon_group d-flex align-center justify-space-between py-10 bbox px-20">
        <div class="time_group d-flex align-center">
          <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon">
          <div class="text">{{$moment(create_time).format('YYYY年MM月DD日')}}</div>
        </div>
        <div class="data_group d-flex align-center"
        >
          <div class="item_group d-flex align-center mr-10"
            v-if="sale"
          >
            <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon">
            <div class="text">{{sale}}</div>
          </div>
          <div class="item_group d-flex align-center"
            v-if="readamount"
          >
            <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon">
            <div class="text">{{readamount}}</div>
          </div>
        </div>
      </div>
      <div class="category_group py-10 bbox px-20"
        v-if="0"
      >会展专业 / 会展营销 / 市场营销 </div>
    </div>
    <div class="payment_group d-flex align-center justify-space-between px-20 py-16">
      <div class="price_group"
        v-if="show_price"
      >
        <div class="price">¥{{price}}</div>
      </div>
      <div class="opeation_group">
        <div class="button"
          v-if="0"
        >加入定制清单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ptask: {
      type: Object,
      default: undefined,
    },
    show_price: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    title(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.title;
      }
      if(ptask){
        return ptask.name;
      }
      return '';
    },
    content(){
      const {pselected,ptask} = this;
      // if(pselected){
        // return pselected.content;
      // }
      if(ptask){
        return '';
      }
      return '';
    },
    create_time(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ptask){
        return ptask.createTime;
      }
      return '';
    },
    free(){
      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.price === 0;
      }
      return false;
    },
    create_user_thumbnail(){

      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.createuserthumbnail;
      }
      return false;
    },
    price(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.price/100;
      }
      if(ptask){
        return ptask.price/100;
      }
      return false;
    },
    sale(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.sale;
      }
      if(ptask){
        return ptask.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.readamount;
      }
      if(ptask){
        return ptask.readamount;
      }
      return undefined;
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_task_unit_hover{
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E8EEFF;
  .title_group{
    padding: 15px 20px;
    flex: 1;
    width: 100%;
    line-height: 24px;
    min-height: 24*4px;
    border-bottom: 2px solid #f8f8f8;
    background: {
      image: url(~@/assets/images/shopping_mail/teaching_task.png);
      repeat: no-repeat;
      position: center right;
    }
  }
  .title{
    font-size: 16px;
    line-height: 24px;
  }
  .descript{
    font-size: 14px;
    color: #999999;
    line-height: 24px;
  }
  .icon_group{
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    .icon{
      margin-right: 4px;
    }
  }
  .category_group{
    font-size: 12px;
    color: #AAAAAA;
    line-height: 16px;
  }
  .payment_group{
    background-color: #f0f0f0;
    .price{
      font-size: 16px;
      font-weight: bold;
      color: #F3580C;
      line-height: 21px;
    }
    .hint{
      font-size: 12px;
      color: #884706;
      line-height: 16px;
    }
    .button{
      width: 120px;
      height: 34px;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 34px;
      text-align: center;
      background-image: linear-gradient(159deg, #68E5B6 0%, #016BA2 100%);
    }
  }
  .title{
    font-size: 14px;
    color: #333333;
    line-height: 24px;
  }
  .free{
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    padding: 0 4px;
    line-height: 18px;
    background: linear-gradient(134deg, #00A9D3 0%, #006BA7 100%);
    border-radius: 4px;
    text-align: center;
  }
}
</style>
